
.configurator__dark-mode-toggle {
    @apply absolute top-6 border-white border-2 bg-black p-2 inline-flex items-center left-6 z-10;
}

.configurator__dark-mode-toggle input {
    @apply sr-only;
}

.configurator__dark-mode-toggle label {
    @apply cursor-pointer leading-none whitespace-nowrap;
}

.configurator__dark-mode-toggle label .toggle {
    @apply inline-block rounded-xl border-2 border-white h-6 w-10 ml-2 relative;
}

.configurator__dark-mode-toggle label .toggle::before {
    content: "";
    @apply w-5 h-5 absolute top-0 left-0 rounded-full bg-white block;
    transition: left .3s ease;
}

.configurator__dark-mode-toggle input:checked + label .toggle {
    @apply bg-red;
}

.configurator__dark-mode-toggle input:checked + label .toggle::before {
    @apply left-4
}