.setup {
    @apply text-sm space-y-2
}

.setup li {
    @apply text-white
}

.setup li.is-active {
    @apply text-red;
}

.setup li.is-active ~ li {
    @apply text-neutral-400;
}