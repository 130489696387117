.configurator__canvas-display {
    @apply relative;
    margin: 3vw;
}


.part {
    @apply absolute;
}

.newel {
    bottom: 0;
    left: 0;
}

.newel + .newel {
    right: 0;
    top: 26px;
    bottom: auto;
    left: auto;
}

.string {
    bottom: 80px;
    left: 30px;
    opacity: 0.1;
}