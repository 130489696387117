

.button--medium {
    @apply w-full font-normal bg-red py-3 antialiased px-8 leading-none inline-flex gap-x-3 items-center text-white text-base rounded-none active:opacity-70;
}

.button--medium svg {
    @apply w-5 h-5
}


.button--medium:disabled {
    @apply bg-neutral-500;
}