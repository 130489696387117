.configurator__finish-selector {
    @apply text-white p-4
}

.configurator__finish-selector ul {
    @apply list-none m-0 antialiased text-white text-sm space-y-5 mt-6
}


.configurator__finish-selector li:not(.hidden) {
    @apply grid grid-cols-2 gap-x-2 
}

.configurator__finish-selector select {
    @apply bg-black appearance-none text-right order-2
}

.configurator__finish-selector select:disabled,
.configurator__finish-selector select:disabled + label {
    @apply text-neutral-400
}