.configurator {
    @apply h-full flex-1 grid grid-rows-1 relative transition-colors overflow-hidden;
    grid-template-columns: 1fr 400px;
}

.configurator.dark {
    @apply bg-black text-white
}

.configurator__canvas {
    @apply row-start-1 col-start-1 col-end-2 relative;
}

.configurator__selector {
    @apply row-start-1 col-start-2 col-end-3 bg-black h-full relative border-l border-neutral-300;
}

.dark .configurator__selector {
    @apply border-neutral-600;
}