.configurator__selector-container nav {
    @apply grid grid-cols-12;

}

.configurator__selector-container nav > button {
    @apply col-span-4 py-4 bg-silver text-xs uppercase text-neutral-400 border-b border-white;
    font-size: 10px;
}

.configurator__selector-container nav > button:disabled {
    @apply bg-black text-white
}

.slides {
    @apply bg-white w-full flex flex-col;
    width: 400px;

    --swiper-pagination-bullet-width: 6px;
    --swiper-pagination-bullet-height: 6px;
}

.swiper-wrapper {
    @apply bg-white;
    height: 35vmin;
}

.swiper-slide {
    @apply p-4
}

.swiper-pagination {
    @apply static bg-black order-2;
}

.swiper-pagination-bullet {
    @apply bg-white opacity-40;
}

.swiper-pagination-bullet-active {
    @apply bg-white opacity-100;
}

.slides button {
    @apply flex flex-col items-center w-full h-full gap-y-4
}

.slides button strong {
    @apply mt-auto text-xs
}

.slides button img {
    max-width: 100%;
    /*max-height: calc(20vmin - 5rem);*/
    object-fit: contain;
    object-position: center;
    @apply h-full
}

.slides button:disabled strong {
    @apply text-red
}

.spindle-selector {
    @apply relative col-span-4;
}

.spindle-selector > button {
    
    @apply py-4 w-full bg-silver text-xs uppercase text-neutral-400 border-b border-white;
    font-size: 10px;

}

.spindle-selector > div {
    @apply hidden absolute bg-black w-full bottom-0 transform translate-y-full z-10 text-white text-xs p-3 flex gap-x-2 items-center;

}

.spindle-selector > button:disabled {
    @apply bg-black text-white
}

.spindle-selector > button:disabled + div {
    @apply flex;
}



.spindle-selector select {
    @apply bg-transparent appearance-none text-red w-full text-center bg-black outline-red
}